.subreddits-wrapper {
    text-align: left;
    background-color: hsla(0, 100%, 100%, 0.07);
    box-shadow:
    /* offset-x | offset-y | blur-radius | spread-radius | color */
    0px 2px 2px 0px hsla(0,0%,0%,0.14), 
    0px 3px 1px -2px hsla(0,0%,0%,0.12), 
    0px 1px 5px 0px hsla(0,0%,0%,0.2);
    max-width: 25%;
    width: 100%;
    margin: 20px 20px 20px 10px;;
    border-radius: 12px;
}

h2 {
    color: #ebebeb;
    margin: 20px 40px;
    font-size: 1.5em;
}

ul {
    list-style: none;
    color: #ebebeb;
}

li {
    margin-left: -20px;
    width: 95%;
}

.subreddit-icon {
    border-radius: 100%;
    height: 30px;
    width: 30px;
    background-color: white;
}

button {
    width: 100%;
    text-align: left;
    display: flex;
    background-color: hsla(0, 100%, 100%, 0.0);
    border: none;
    color: #ebebeb;
    font-size: 1em;
    padding: 20px 20px;
}

button:hover {
    background-color: hsla(0, 100%, 100%, 0.1);
    border-radius: 12px;
}

li.subreddit.active {
    background-color: hsla(0, 100%, 100%, 0.1);
    border-radius: 12px;
    width: 95%;
}

p {
    margin: auto 10px;
}

@media only screen and (max-width: 500px) {
    .subreddits-wrapper {
        max-width: 90%;
        height: 160px;
    }

    h2 {
        margin-left: 20px;
    }

    ul {
        overflow-x: auto;
        white-space: nowrap;
    }

    li {
        display: inline-block;
        width: auto;
        margin-left: -20px;
        margin-right: 25px;
    }

    li.subreddit.active {
        width: auto;
    }
}