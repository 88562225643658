header {
    display: grid;
    grid-column: 1/-1;
    grid-template-columns: 1fr 1fr 1fr;
    align-content: center;
    background-color: hsla(0, 100%, 100%, 0.07);
}

h1 {
    color: white;
}

#minimal {
    color: gold;
}

@media screen and (max-width: 500px) {
    h1 {
        margin: 20px 0px 0px 0px;
    }

    header {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}