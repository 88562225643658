.App {
  text-align: center;
  background-color: #121212;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.body {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

@media only screen and (max-width: 500px) {
  .body {
    flex-wrap: wrap-reverse;
  }
}